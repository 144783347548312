<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col
      cols="12"
      sm="12"
      md="10"
    >
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-form
          @submit.prevent="register"
          autocomplete="off"
        >
          <v-card class="elevation-12">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>Registrácia</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>Údaje vyplňuj riadne a pravdivo. Nepravdivo vyplnený formulár o tvojich údajoch môže byť dôvodom na zrušenie tvojho profilu a tým všetkých aktivít a práv na tejto stránke.</v-card-text>
            <v-card-text>

              <v-card class="mb-3">
                <v-card-title>Základné údaje</v-card-title>
                <v-card-text>

                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Prihlasovacie meno"
                        rules="required"
                      >
                        <v-text-field
                          v-model="form.username"
                          :error-messages="errors"
                          label="Prihlasovacie meno"
                          name="login"
                          prepend-icon="mdi-account"
                          type="text"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >

                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        vid="email1"
                        :rules="{required: true, email: true}"
                      >
                        <v-text-field
                          v-model="form.email"
                          :error-messages="errors"
                          label="Email"
                          name="email"
                          prepend-icon="mdi-email"
                          type="text"
                          @click.right.prevent
                          @copy.prevent
                          @paste.prevent
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email znovu"
                        rules="confirmed:email1"
                      >

                        <v-text-field
                          v-model="form.email2"
                          :error-messages="errors"
                          label="Email znovu"
                          name="email2"
                          prepend-icon="mdi-email"
                          type="text"
                          @click.right.prevent
                          @copy.prevent
                          @paste.prevent
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Heslo"
                        rules="required|min:10"
                        vid="password1"
                      >

                        <v-text-field
                          id="password"
                          v-model="form.password"
                          :error-messages="errors"
                          label="Heslo"
                          name="password"
                          prepend-icon="mdi-lock"
                          :type="show1 ? 'text' : 'password'"
                          @click:append="show1 = !show1"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          @click.right.prevent
                          @copy.prevent
                          @paste.prevent
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Heslo znovu"
                        rules="confirmed:password1"
                      >

                        <v-text-field
                          id="password"
                          v-model="form.password2"
                          :error-messages="errors"
                          label="Heslo znovu"
                          name="password2"
                          prepend-icon="mdi-lock"
                          :type="show2 ? 'text' : 'password'"
                          @click:append="show2 = !show2"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          @click.right.prevent
                          @copy.prevent
                          @paste.prevent
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Meno"
                        rules="required"
                      >

                        <v-text-field
                          v-model="form.first_name"
                          :error-messages="errors"
                          label="Meno"
                          name="first_name"
                          prepend-icon="mdi-account"
                          type="text"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Priezvisko"
                        rules="required"
                      >

                        <v-text-field
                          v-model="form.last_name"
                          :error-messages="errors"
                          label="Priezvisko"
                          name="last_name"
                          prepend-icon="mdi-account"
                          type="text"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="form.gender"
                        label="Pohlavie"
                        prepend-icon="mdi-account"
                        :items="selects"
                      />
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Detaily</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      align="center"
                    >
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            x-large
                            v-bind="attrs"
                            v-on="on"
                            color="error"
                            outlined
                          >
                            DÔLEŽITÉ
                          </v-btn>
                        </template>
                        <v-card><v-card-text>
                            <p>Ak si vyplníš aj IBAN a overíš sa prostredníctvom platby z banky, tak budeš mať viac výhod oproti tým, ktorí sa takýmto spôsobom neoveria. A zároveň nás môžeš aj trochu podporiť.</p>

                            <p>Pýtaš sa načo je nám číslo tvojho účtu (IBAN)?
                              Na overenie tvojej identity prostredníctvom bankového účtu. Prostredníctvom prijatej platby z tvojho účtu dokážeme jednoducho spárovať teba ako skutočnú osobu s sobou, ktorá bude vystupovať na týchto platformách a tým výrazne znížime a eliminujeme vytváranie falošných identít, ktoré by mohli negatívne ovplyvňovať komentáre v diskusiách. A o to nám tu predsa ide. Aby sme tu mali čo najkorektnejšie informácie od skutočných osôb a nie od imaginárnych trolov.</p>

                            <p> Číslo tvojho účtu a ani číslo účtu nikoho sa nedá zneužiť. Len heslá a pin kódy k účtu sa dajú zneužiť.
                              A TIE OD TEBA NEŽIADAME! A nikdy ani nebudeme.</p>

                            <p> Veď už len plynárne, elektrárne, či mobilní operátori majú zverejnené svoje čísla IBAN na každej faktúre a aj na ich webových sídlach a to oni na účtoch majú podstatne viac peňazí ako my. Hocikde dávaš číslo tvojho účtu a keď niekomu pošleš peniaze, tak to číslo účtu prijímateľ vidí.</p>

                            <p>My tu chceme využiť moderné informačné technológie na to aby sme AUTOMATIZOVANE, teda bez nároku na ďalšie personálne zaťaženie, overili tvoju unikátnu identitu. Prebieha to tak, že ak sa v našom systéme objaví platba z účtu, ktorý sa zhoduje s tým číslom účtu IBAN, ktoré uvedieš sem pri registrácií, bude táto platba priradená k tebe. Aj vo svojom profile sa Ti táto platba AUTOMATIZOVANE pripočíta k celkovej sume, ktorú si zaslal/a v náš prospech a to aj opakovane – teda je to kumulatívny údaj. Náš systém komunikuje s bankovým systémom a platby sa spárujú. Preto Ťa prosíme aby si správne uviedol/a číslo tvojho účtu a pri každej platbe používal/a ako VS (variabilný symbol) aj tvoje unikátne ID (identifikačné číslo), ktoré sa Ti vytvorí automaticky po tejto úspešnej registrácií a budeš ho vidieť vo svojom profile.</p>

                            <p> Ďakujeme za pochopenie.</p>

                            <p>Boris Wagner</p>
                          </v-card-text></v-card>
                      </v-menu>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="IBAN"
                        vid="iban1"
                        :rules="{
          required: false,
          iban: '^([0-9a-zA-Z]{4}\\s){5}[0-9a-zA-Z]{4}$'}"
                      >
                        <v-text-field
                          :error-messages="errors"
                          v-model="form.bank_account"
                          class="custom-iban"
                          label="IBAN - NEPOVINNÝ údaj pre registráciu"
                          name="bank_account"
                          prepend-icon="mdi-bank"
                          type="text"
                          v-mask="'SK## #### #### #### #### ####'"
                          @click.right.prevent
                          @copy.prevent
                          @paste.prevent
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="IBAN"
                        :rules="{
          required: false,
          confirmed: 'iban1'}"
                      >
                        <v-text-field
                          :error-messages="errors"
                          v-model="form.bank_account2"
                          class="custom-iban"
                          label="IBAN znovu - NEPOVINNÝ údaj pre registráciu"
                          name="bank_account2"
                          prepend-icon="mdi-bank"
                          type="text"
                          v-mask="'SK## #### #### #### #### ####'"
                          @click.right.prevent
                          @copy.prevent
                          @paste.prevent
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Telefónne číslo"
                        vid="phone1"
                        :rules="{
          required: true,
          phone: '^\\+421[1-9]\\d{8}$'}"
                      >
                        <v-text-field
                          v-model="form.phone_number"
                          :error-messages="errors"
                          label="Telefónne číslo"
                          name="phone_number"
                          prepend-icon="mdi-phone"
                          type="text"
                          required
                          @click.right.prevent
                          @copy.prevent
                          @paste.prevent
                        ></v-text-field>

                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Telefónne číslo"
                        :rules="{confirmed: 'phone1'}"
                      >
                        <v-text-field
                          v-model="form.phone_number2"
                          :error-messages="errors"
                          label="Telefónne číslo znovu"
                          name="phone_number2"
                          prepend-icon="mdi-phone"
                          type="text"
                          required
                          @click.right.prevent
                          @copy.prevent
                          @paste.prevent
                        ></v-text-field>

                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Ulica"
                        rules="required"
                      >

                        <v-text-field
                          v-model="form.address_street"
                          :error-messages="errors"
                          label="Ulica"
                          name="address_street"
                          prepend-icon="mdi-map-marker"
                          type="text"
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Číslo"
                        rules="required"
                      >

                        <v-text-field
                          v-model="form.address_number"
                          :error-messages="errors"
                          label="Číslo"
                          name="address_number"
                          prepend-icon="mdi-map-marker"
                          type="text"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Mesto/Obec"
                        rules="required"
                      >

                        <v-autocomplete
                          v-model="form.address_town"
                          :error-messages="errors"
                          :items="itemsTown"
                          :search-input.sync="searchTown"
                          item-text="name"
                          item-value="name"
                          label="Mesto/Obec"
                          placeholder="Mesto/Obec"
                          prepend-icon="mdi-map-marker"
                          @change="townChanged($event)"
                          return-object
                          hide-no-data
                        ></v-autocomplete>
                      </validation-provider>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="PSČ"
                        rules="required"
                      >

                        <v-text-field
                          v-model="form.address_zip_code"
                          :error-messages="errors"
                          label="PSČ"
                          name="address_zip_code"
                          prepend-icon="mdi-map-marker"
                          type="text"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="form.address_okres"
                        disabled
                        label="Okres"
                        name="address_okres"
                        prepend-icon="mdi-map-marker"
                        type="text"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="form.address_kraj"
                        disabled
                        label="Kraj"
                        name="address_kraj"
                        prepend-icon="mdi-map-marker"
                        type="text"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="form.address_country"
                        disabled
                        label="Krajina"
                        name="address_country"
                        prepend-icon="mdi-flag"
                        type="text"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-checkbox
                v-model="agreement"
                label="Súhlasím s podmienkami používania tejto stránky"
                name="agreement"
              >
              </v-checkbox>
              <a
                href="https://docs.google.com/gview?embedded=true&url=https://api.nasehnutie.sk/uploads/cc7ffb9f-9079-4ff0-829d-a49b6ad89671.pdf"
                target="_blank"
              >Podmienky používania stránky nasehnutie.sk</a>
              <g-d-p-r-consent v-model="agreement_gdpr"></g-d-p-r-consent>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="!agreement || invalid || !agreement_gdpr"
                  color="primary"
                  @click="register"
                >
                  Registrovať sa
                </v-btn>
              </v-card-actions>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { required, regex, confirmed, email, min } from 'vee-validate/dist/rules'
import GDPRConsent from '../components/GDPRConsent.vue';
import VueMask from 'v-mask'
Vue.use(VueMask);

const apiService = new APIService();

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} nemôže byť prázdne',
})

extend('phone', {
  ...regex,
  message: 'Číslo zadávaj v medzinárodnom tvare (+421) bez medzier',
})

extend('iban', {
  ...regex,
  message: 'IBAN zadávaj v tvare s medzerami. Napr. SK31 1200 0000 1987 4263 7541',
})

extend('email', {
  ...email,
  message: 'Emailová adresa nie je korektná. Napr. info@nasehnutie.sk',
})

extend('confirmed', {
  ...confirmed,
  message: 'Znovu zadaná hodnota sa nezhoduje',
})

extend('min', {
  ...min,
  message: 'Minimálna dĺžka hesla je 10 znakov',
})


export default {
  props: {
    source: String,
  },
  data() {
    return {
      show1: false,
      show2: false,
      loading: false,
      drawer: null,

      agreement: false,
      agreement_gdpr: false,

      form: {
        username: "",
        password: "",
        password2: "",
        email: "",
        email2: "",
        first_name: "",
        last_name: "",
        bank_account: "",
        bank_account2: "",
        phone_number: "",
        phone_number2: "",
        gender: "",
      },
      selects: [
        { text: "Muž", value: "MAN" },
        { text: "Žena", value: "WOMAN" },
      ],

      searchTown: null,
      itemsTown: []


    };
  },

  watch: {
    searchTown(val) {
      this.searchTownApi(val);
    }
  },

  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    GDPRConsent
  },


  methods: {
    townChanged(event) {
      if (event.okres.name)
        this.form.address_okres = event.okres.name;
      if (event.okres.kraj.name)
        this.form.address_kraj = event.okres.kraj.name;
      if (event.okres.kraj.krajina.name)
        this.form.address_country = event.okres.kraj.krajina.name;
    },

    searchTownApi(val) {
      apiService
        .searchObec(val)
        .then((response) => {
          this.itemsTown = response;
        });
    },

    register() {
      this.$refs.observer.validate();

      this.loading = true;
      if (this.form.address_town && this.form.address_town.name)
        this.form.address_town = this.form.address_town.name;

      if (!this.form.bank_account)
        this.form.bank_account = null;


      apiService.register(this.form).then((response) => {
        this.form = response;
        this.loading = false;
        this.$router.push({ "path": "/register_verify" });
      });
    },
  },
};
</script>

<style>
.custom-iban .v-label {
  color: red !important;
  opacity: 1;
}
</style>